import {
    executeSupabaseQuery,
    skipQuery,
    useQuery,
} from "../utils/supabase/useQuery";
import { flow, pipe } from "fp-ts/function";
import { array, date, eq, monoid, number, option, ord } from "fp-ts";
import {
    EventId,
    events,
    EventUpdateResult,
    eventUpdateResultCodec,
    fileTable,
    imagesTable,
    raceCategoryTable,
    raceSegmentTable,
    raceTable,
    segmentTable,
} from "../domain/event";
import { categoriesTable } from "../domain/category";
import * as t from "io-ts";
import { Option } from "fp-ts/Option";
import { getOptionReverseOrd, pick } from "../utils/fp";
import { column, columns, join, TableRowT } from "../utils/pg-ts/pg-ts";
import { SupabaseClient } from "@supabase/supabase-js";
import * as React from "react";
import { gunshotsTable } from "../domain/timing";
import {
    Distance,
    distanceAdd,
    nullable,
    validIsoDateStringFromDateCodec,
} from "../domain/common";
import { contramap } from "fp-ts/Ord";
import { Subdomain } from "../utils/subdomain/subdomain";
import { supabase } from "../utils/supabase/init";
import { paymentMethodCodec, paymentMethodTable } from "../domain/payment";

export const eventDetailQuery = (eventId: EventId) => {
    return (supabase: SupabaseClient) =>
        supabase
            .from(events.name)
            .select(
                columns(
                    eventCols,
                    join(fileTable, eventFilesCols, eventFilesAlias),
                    join(
                        imagesTable,
                        eventDetailBannerImageCols,
                        bannerImageAlias
                    ),
                    join(
                        paymentMethodTable,
                        paymentMethodColumns,
                        paymentMethodAlias
                    ),
                    join(
                        raceTable,
                        eventDetailRaceCols,
                        undefined,
                        false,
                        join(
                            raceSegmentTable,
                            raceSegmentCols,
                            raceSegmentAlias,
                            false,
                            join(segmentTable, segmentCols, segmentAlias)
                        ),
                        join(
                            raceCategoryTable,
                            raceCategoryCols,
                            raceCategoryAlias,
                            false,
                            join(categoriesTable, categoryCols, categoryAlias)
                        ),
                        join(gunshotsTable, gunshotsColumns, gunshotAlias)
                    )
                )
            )
            .eq(column(events, "id"), eventId)
            .order("rank", { foreignTable: raceTable.name })
            // TODO not working .order("ordering", { foreignTable: raceSegmentTable.name })
            .single();
};

export const eventDetailQueryId = (eventId: string | EventId) =>
    `eventDetail_${eventId}`;
export const useEventDetail = (eventId: Option<EventId>) => {
    const query = useQuery(
        pipe(
            eventId,
            option.fold(
                () => "",
                (id) => `${id}`
            ),
            eventDetailQueryId
        ),
        pipe(eventId, option.fold(skipQuery, eventDetailQuery)),
        eventDetailCodec,
        undefined,
        option.isNone(eventId)
    );

    const onEventUpdate = React.useCallback(
        (res: EventUpdateResult) => {
            query.mutate(
                flow(
                    option.fromNullable,
                    option.map(
                        (currentDetail): EventDetail => ({
                            ...currentDetail,
                            ...res,
                        })
                    ),
                    option.toUndefined
                ),
                true // to be sure
            );
        },
        [query.mutate]
    );

    return { ...query, onEventUpdate };
};

const eventCols = pipe(
    events.columns,
    pick(
        "id",
        "organization_id",
        "title",
        "subtitle",
        "date",
        "description",
        "location",
        "web",
        "registration",
        "results_published"
    )
);
export const eventDetailRaceCols = pipe(
    raceTable.columns,
    pick(
        "sport",
        "title",
        "id",
        "start_time",
        "kind",
        "rank",
        "club_scoring",
        "fastest_expected_pace",
        "registration_fields"
    )
);
const raceSegmentCols = pipe(
    raceSegmentTable.columns,
    pick("segment_id", "ordering")
);
const raceSegmentAlias = raceSegmentTable.name;
const segmentCols = pipe(
    segmentTable.columns,
    pick("id", "distance", "geojson")
);
const segmentDetailCodec = t.type(segmentCols, "SegmentDetail");
export type SegmentDetail = t.TypeOf<typeof segmentDetailCodec>;
const segmentAlias = "segment";

const raceCategoryCols = pipe(
    raceCategoryTable.columns,
    pick("category_id", "entry_fee", "entry_fee_currency")
);
const raceCategoryAlias = raceCategoryTable.name;
const categoryCols = pipe(
    categoriesTable.columns,
    pick("id", "title", "description", "sex", "age_gte", "age_lte")
);
const raceCategoryCodec = t.type(categoryCols, "RaceCategory");
export type RaceCategory = t.TypeOf<typeof raceCategoryCodec>;
export const raceCategoryByIdEq = pipe(
    number.Eq,
    eq.contramap((raceCategory: RaceCategory) => raceCategory.id)
);
const categoryAlias = "category";

const eventDetailBannerImageCols = pipe(
    imagesTable.columns,
    pick("id", "url", "metadata")
);
const eventDetailBannerImageCodec = t.type(eventDetailBannerImageCols);
export type EventDetailBannerImage = t.TypeOf<
    typeof eventDetailBannerImageCodec
>;
const bannerImageAlias = "banner_image";

const eventFilesAlias = "files";
const eventFilesCols = pipe(fileTable.columns, pick("id", "url", "title"));
const eventFileCodec = t.type(eventFilesCols);
const gunshotAlias = "gunshot" as const;
const gunshotsColumns = pipe(gunshotsTable.columns, pick("id", "gunshot"));
const raceSegmentCodec = t.type({
    ...raceSegmentCols,
    // TODO load segments geojson in asyn way...
    [segmentAlias]: segmentDetailCodec,
});
const paymentMethodColumns = pipe(
    paymentMethodTable.columns,
    pick("kind", "iban")
);
const paymentMethodAlias = "paymentMethod";

export type RaceSegment = t.TypeOf<typeof raceSegmentCodec>;

export const eventDetailCodec = t.type(
    {
        ...eventCols,
        races: t.array(
            t.type(
                {
                    ...eventDetailRaceCols,
                    [gunshotAlias]: nullable(t.type(gunshotsColumns)),
                    [raceSegmentAlias]: t.array(raceSegmentCodec),
                    [raceCategoryAlias]: t.array(
                        t.type({
                            ...raceCategoryCols,
                            [categoryAlias]: raceCategoryCodec,
                        })
                    ),
                },
                "EventDetailRace"
            )
        ),
        [bannerImageAlias]: t.union([eventDetailBannerImageCodec, t.null]),
        [eventFilesAlias]: t.array(eventFileCodec),
        [paymentMethodAlias]: nullable(paymentMethodCodec),
    },
    "EventDetail"
);
export type EventDetail = t.TypeOf<typeof eventDetailCodec>;
export type EventDetailRace = EventDetail["races"][0];

interface WithStartDate extends Pick<EventDetailRace, "start_time"> {}
// TODO move to race domain
export const ordByRaceStartDate = pipe(
    date.Ord,
    option.getOrd,
    contramap((race: WithStartDate) =>
        pipe(
            race.start_time,
            option.fromNullable,
            option.map(validIsoDateStringFromDateCodec.encode)
        )
    )
);

interface WithRank extends Pick<EventDetailRace, "rank"> {}
const ordByRank = pipe(
    number.Ord,
    getOptionReverseOrd,
    contramap((r: WithRank) => option.fromNullable(r.rank))
);
export const eventDetailRaceOrd = monoid.concatAll(
    ord.getMonoid<EventDetailRace>()
)([ordByRank, ordByRaceStartDate]);

export const raceDistance = (race: EventDetailRace) =>
    pipe(
        race.race_segment,
        array.map((s) => s.segment.distance),
        array.reduce(0 as Distance, distanceAdd)
    );

export interface EventUpdateDeps {
    supabase: SupabaseClient;
    eventId: EventId;
    data: Partial<TableRowT<typeof events>>;
}
export const executeEventUpdate = (deps: EventUpdateDeps) =>
    executeSupabaseQuery(
        "updateEvent",
        eventUpdateResultCodec,
        (supabase) =>
            supabase
                .from(events.name) // TODO move to separate used api calls file, with tests
                .update(deps.data)
                .eq(column(events, "id"), deps.eventId)
                .single(),
        deps.supabase
    );

export const nearestEventImageCols = pipe(
    imagesTable.columns,
    pick("url", "metadata")
);

export const nearestEventImageCodec = t.union([
    t.type(nearestEventImageCols),
    t.null,
]);
export type NearestEventImage = t.TypeOf<typeof nearestEventImageCodec>;

export const nearestEventRaceCols = pipe(raceTable.columns, pick("start_time"));
export const nearestEventRaceCodec = t.type(nearestEventRaceCols);

const nearestEventsFields = pipe(
    events.columns,
    pick(
        "id",
        "title",
        "subtitle",
        "description",
        "date",
        "location"
        // "date_canceled" // TODO use in ui + use in time_to_start computed field (probably not a good idea query computed value, query / order by resulting date and time_to_start compute on client)
    )
);

const nearestEventImageAlias = "banner_image";
const nearestEventRaceAlias = "races";

const nearestEventCodec = t.type({
    ...nearestEventsFields,
    [nearestEventImageAlias]: t.union([t.type(nearestEventImageCols), t.null]),
    [nearestEventRaceAlias]: t.array(nearestEventRaceCodec),
});
export type NearestEvent = t.TypeOf<typeof nearestEventCodec>;

export const nearestEvents = (orgSubdomain: Subdomain) => {
    return pipe(
        executeSupabaseQuery(
            "nearestEvents",
            t.array(nearestEventCodec),
            (supabase) =>
                supabase
                    .from("events_published") // TODO pg-ts field/column names
                    .select(
                        columns(
                            nearestEventsFields,
                            join(
                                imagesTable,
                                nearestEventImageCols,
                                nearestEventImageAlias
                            ),
                            join(
                                raceTable,
                                nearestEventRaceCols,
                                nearestEventRaceAlias
                            )
                        )
                    )
                    .eq("subdomain", orgSubdomain)
                    .gt("time_to_start", -24 * 3600) // include also events starting today
                    .order("time_to_start", { ascending: true })
                    .range(0, 2),
            supabase
        )
    );
};
