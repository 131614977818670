import * as React from "react";
import { MDXRemote } from "next-mdx-remote";
import { MdxContent } from "../../domain/common";
import { ExternalLink } from "../../components/common/Link";
import { Typography } from "@mui/material";
import { MDXComponents } from "mdx/types";

interface Props {
    mdxContent: MdxContent;
}

export const Mdx = (p: Props) => {
    return <MDXRemote {...p.mdxContent} components={components} />;
};

const components: MDXComponents = {
    a: ExternalLink,
    h1: (p) => (
        <Typography variant={"h1"} gutterBottom>
            {p.children}
        </Typography>
    ),
    h2: (p) => (
        <Typography variant={"h2"} gutterBottom>
            {p.children}
        </Typography>
    ),
    h3: (p) => (
        <Typography variant={"h3"} gutterBottom>
            {p.children}
        </Typography>
    ),
    p: (p) => <Typography gutterBottom>{p.children}</Typography>,
};
