import * as React from "react";
import { Subdomain } from "../../utils/subdomain/subdomain";
import { EventId } from "../../domain/event";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
} from "@mui/material";
import Image from "next/legacy/image";
import { constNull, pipe } from "fp-ts/function";
import { array, nonEmptyArray, option } from "fp-ts";
import { LocalDate } from "../common/date/LocalDate";
import {
    NearestEvent,
    NearestEventImage,
    ordByRaceStartDate,
} from "../../hooks/event";
import { InternalLink } from "../common/Link";
import { eventRoute } from "../../routes/org";
import { useTranslation } from "../../utils/i18n/lang";
import { messages } from "../../utils/i18n/i18n";
import { validIsoDateStringFromDateCodec } from "../../domain/common";
import { Countdown } from "../common/date/Countdown";

interface Props {
    image: NearestEventImage;
    children: React.ReactNode;
    orgSubdomain: Subdomain;
    eventId: EventId;
    paddingTop?: string;
}

export const EventCard = (p: Props) => {
    return (
        <Card
            elevation={3}
            square
            sx={{
                position: "relative",
                backgroundColor: "rgba(0, 0, 0, 0)",
                height: "100%",
                color: (theme) => theme.palette.background.default,
            }}
        >
            <InternalLink
                href={eventRoute.route({
                    subdomain: p.orgSubdomain,
                    eventId: p.eventId,
                })}
            >
                <CardActionArea sx={{ height: "100%" }}>
                    <Box sx={{ height: "100%" }}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                bottom: "0",
                                right: "0",
                                zIndex: "1",
                            }}
                        >
                            {p.image == null ? null : (
                                <Image
                                    alt={"event banner"}
                                    src={p.image.url}
                                    layout={"fill"}
                                    objectPosition={"50% 50%"}
                                    objectFit={"cover"}
                                    sizes={"50vw"}
                                />
                            )}
                        </Box>

                        <CardContent
                            sx={{
                                height: "100%",
                                zIndex: "2",
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                paddingTop: p.paddingTop,
                                background:
                                    "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 40%, rgba(0,0,0,0.65) 60%, rgba(0,0,0,0.9) 100%)",
                                ":hover": {
                                    color: (theme) =>
                                        theme.palette.primary.light,
                                },
                            }}
                        >
                            {p.children}
                        </CardContent>
                    </Box>
                </CardActionArea>
            </InternalLink>
        </Card>
    );
};

interface EventCardLocationDateProps
    extends Pick<NearestEvent, "location" | "date" | "races"> {
    showCountDown?: boolean;
}

export const EventCardLocationDate = (p: EventCardLocationDateProps) => {
    return pipe(
        [
            p.date == null ? null : <LocalDate key={"date"} date={p.date} />,
            p.location == null ? null : (
                <span key={"location"}>{p.location}</span>
            ),
        ],
        array.filterMap(option.fromNullable),
        array.intersperse(<span key={"joiner"}>, </span>), // todo in case of multiple, there will be duplicated key
        nonEmptyArray.fromArray,
        option.fold(constNull, (segments) => (
            <Typography variant={"h5"} gutterBottom>
                {segments}
            </Typography>
        )),
        (leftSide) => {
            const rightSide = pipe(
                p.races,
                array.sort(ordByRaceStartDate),
                array.head,
                option.map((firstRace) => {
                    const firstStartDate =
                        validIsoDateStringFromDateCodec.encode(
                            firstRace.start_time
                        );

                    return <Countdown date={firstStartDate} />;
                }),
                option.toNullable
            );

            if (leftSide != null && rightSide != null) {
                return (
                    <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Grid item>{leftSide}</Grid>
                        <Grid item>{rightSide}</Grid>
                    </Grid>
                );
            } else {
                return (
                    <>
                        {leftSide}
                        {rightSide}
                    </>
                );
            }
        }
    );
};

interface EventCardLayoutProps {
    children: React.ReactNode;
}
export const EventCardFooter = (p: EventCardLayoutProps) => {
    const t = useTranslation(msg);
    return (
        <Grid container spacing={1} alignItems={"flex-end"}>
            <Grid item xs={12} md={9}>
                {p.children}
            </Grid>
            <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
                <Button variant={"contained"} size={"large"}>
                    {t.moreBtn}
                </Button>
            </Grid>
        </Grid>
    );
};

const msg = messages(
    {
        moreBtn: "Find out more",
    },
    {
        cs: {
            moreBtn: "Více",
        },
    }
);
