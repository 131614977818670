import * as React from "react";
import { LocalDateString, ValidIsoDateString } from "../../../domain/common";
import { format } from "date-fns/fp";
import { Messages } from "../../../utils/i18n/i18n";
import { pipe } from "fp-ts/function";
import { useTranslation } from "../../../utils/i18n/lang";
import { NoSsr } from "@mui/material";
import { parseISO } from "date-fns";

type DateFormat = typeof defaultDateFormat;

interface Props {
    date: LocalDateString | ValidIsoDateString;
    format?: DateFormat;
}

export type DateFnsFormat = string; // https://date-fns.org/v2.28.0/docs/format
const defaultDateFormat: Messages<DateFnsFormat> = {
    en: "LLL d, yyyy", // Jan 23, 2022
    cs: "d.M.yyyy",
};

export const dmFormat: Messages<DateFnsFormat> = {
    en: "LLL d", // Jan 23
    cs: "d.M.",
};

export const dateTimeFormat: Messages<DateFnsFormat> = {
    en: "LLL d, yyyy p", // 12:00 AM
    cs: "d.M.yyyy HH:mm",
};

export const LocalDate = (p: Props) => {
    const dateFormat = useTranslation(p.format || defaultDateFormat);

    return (
        <time dateTime={p.date}>
            <NoSsr
                fallback={p.date}
                defer={true} // prevents rehydration errors
            >
                {localDate(p.date, dateFormat)}
            </NoSsr>
        </time>
    );
};

export const localDate = (
    date: LocalDateString | ValidIsoDateString,
    localDateFormat: string
) => pipe(date, parseISO, format(localDateFormat));
