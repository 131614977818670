import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import {
    commonIdCodec,
    nullable,
    pgIntegerCodec,
    pgSmallintCodec,
    positiveNumberCodec,
    trimmedStringCodec,
} from "./common";
import { Rank } from "./result";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { optionOrChain } from "../utils/fp";
import { table } from "../utils/pg-ts/pg-ts";
import { NonEmptyString } from "io-ts-types";
import { raceTable } from "./event";
import { subdomainCodec } from "../utils/subdomain/subdomain";
import { messages } from "../utils/i18n/i18n";

export const serialPointsCodec = t.intersection(
    [pgIntegerCodec, positiveNumberCodec],
    "SerialPoints"
);
export type SerialPoints = t.TypeOf<typeof serialPointsCodec>;

type RankPointMap = Record<number, SerialPoints>;

const topResultsCountCodec = t.intersection(
    [pgSmallintCodec, positiveNumberCodec],
    "TopResultsCount"
);
export type TopResultCount = t.TypeOf<typeof topResultsCountCodec>;

export const seriesTable = table("series", {
    id: commonIdCodec,
    title: trimmedStringCodec.pipe(NonEmptyString),
    top_results_count: nullable(topResultsCountCodec),
    rank: nullable(pgIntegerCodec),
});

// todo pg ts view?
export const seriesPublishedView = table("series_published", {
    ...seriesTable.columns,
    subdomain: subdomainCodec,
});

export const seriesRaceTable = table("series_race", {
    race_id: raceTable.columns.id,
    series_id: seriesTable.columns.id,
});

const points190 = {
    1: 190,
    2: 176,
    3: 163,
    4: 151,
    5: 140,
    6: 130,
    7: 121,
    8: 113,
    9: 106,
    10: 100,
    11: 96,
    12: 93,
    13: 91,
    14: 89,
    15: 87,
    16: 85,
    17: 84,
    18: 83,
    19: 82,
    20: 81,
    21: 80,
    22: 79,
    23: 78,
    24: 77,
    25: 76,
    26: 75,
    27: 74,
    28: 73,
    29: 72,
    30: 71,
    31: 70,
    32: 69,
    33: 68,
    34: 67,
    35: 66,
    36: 65,
    37: 64,
    38: 63,
    39: 62,
    40: 61,
    41: 60,
    42: 59,
    43: 58,
    44: 57,
    45: 56,
    46: 55,
    47: 54,
    48: 53,
    49: 52,
    50: 51,
    51: 50,
    52: 50,
    53: 49,
    54: 49,
    55: 48,
    56: 48,
    57: 47,
    58: 47,
    59: 46,
    60: 46,
    61: 45,
    62: 45,
    63: 44,
    64: 44,
    65: 43,
    66: 43,
    67: 42,
    68: 42,
    69: 41,
    70: 41,
    71: 40,
    72: 40,
    73: 39,
    74: 39,
    75: 38,
    76: 38,
    77: 37,
    78: 37,
    79: 36,
    80: 36,
    81: 35,
    82: 35,
    83: 34,
    84: 34,
    85: 33,
    86: 33,
    87: 32,
    88: 32,
    89: 31,
    90: 31,
    91: 30,
    92: 30,
    93: 30,
    94: 29,
    95: 29,
    96: 29,
    97: 28,
    98: 28,
    99: 28,
    100: 27,
    101: 27,
    102: 27,
    103: 26,
    104: 26,
    105: 26,
    106: 25,
    107: 25,
    108: 25,
    109: 24,
    110: 24,
    111: 24,
    112: 23,
    113: 23,
    114: 23,
    115: 22,
    116: 22,
    117: 22,
    118: 21,
    119: 21,
    120: 21,
    121: 20,
    122: 20,
    123: 20,
    124: 19,
    125: 19,
    126: 19,
    127: 18,
    128: 18,
    129: 18,
    130: 17,
    131: 17,
    132: 17,
    133: 16,
    134: 16,
    135: 16,
    136: 15,
    137: 15,
    138: 15,
    139: 14,
    140: 14,
    141: 14,
    142: 13,
    143: 13,
    144: 13,
    145: 12,
    146: 12,
    147: 12,
    148: 11,
    149: 11,
    150: 11,
    151: 10,
    152: 10,
    153: 10,
    154: 9,
    155: 9,
    156: 9,
    157: 8,
    158: 8,
    159: 8,
    160: 7,
    161: 7,
    162: 7,
    163: 6,
    164: 6,
    165: 6,
    166: 5,
    167: 5,
    168: 5,
    169: 4,
    170: 4,
    171: 4,
    172: 3,
    173: 3,
    174: 3,
    175: 2,
    176: 2,
    177: 2,
} as unknown as RankPointMap;
const points110 = {
    1: 110,
    2: 100,
    3: 91,
    4: 83,
    5: 77,
    6: 71,
    7: 67,
    8: 64,
    9: 62,
    10: 61,
    11: 60,
    12: 59,
    13: 58,
    14: 57,
    15: 56,
    16: 55,
    17: 54,
    18: 53,
    19: 52,
    20: 51,
    21: 50,
    22: 49,
    23: 48,
    24: 47,
    25: 46,
    26: 45,
    27: 44,
    28: 43,
    29: 42,
    30: 41,
    31: 40,
    32: 39,
    33: 38,
    34: 37,
    35: 36,
    36: 35,
    37: 34,
    38: 33,
    39: 32,
    40: 31,
    41: 30,
    42: 29,
    43: 28,
    44: 27,
    45: 26,
    46: 25,
    47: 24,
    48: 23,
    49: 22,
    50: 21,
    51: 20,
    52: 19,
    53: 18,
    54: 17,
    55: 16,
    56: 15,
    57: 14,
    58: 13,
    59: 12,
    60: 11,
    61: 10,
    62: 9,
    63: 8,
    64: 7,
    65: 6,
    66: 5,
    67: 4,
    68: 3,
    69: 2,
    70: 1,
} as unknown as RankPointMap;
const points50 = {
    1: 50,
    2: 45,
    3: 41,
    4: 38,
    5: 36,
    6: 35,
    7: 34,
    8: 33,
    9: 32,
    10: 31,
    11: 30,
    12: 29,
    13: 28,
    14: 27,
    15: 26,
    16: 25,
    17: 24,
    18: 23,
    19: 22,
    20: 21,
    21: 20,
    22: 19,
    23: 18,
    24: 17,
    25: 16,
    26: 15,
    27: 14,
    28: 13,
    29: 12,
    30: 11,
    31: 10,
    32: 9,
    33: 8,
    34: 7,
    35: 6,
    36: 5,
    37: 4,
    38: 3,
    39: 2,
    40: 1,
} as unknown as RankPointMap;

export interface Scoring {
    title: string;
    map: RankPointMap;
}
export const pointsMapping: Array<Scoring> = [
    { title: "190b za 1. místo", map: points190 },
    { title: "110b za 1. místo", map: points110 },
    { title: "50b za 1. místo", map: points50 },
];

// TODO store fallback value on scoring level? can be optional => for undefined ranks nothing
const pointsFallback = () => option.some(1 as SerialPoints);

export const scoringPoints =
    (scoring: Scoring) =>
    (rank: Rank): Option<SerialPoints> =>
        pipe(
            scoring.map[rank],
            option.fromNullable,
            optionOrChain(pointsFallback)
        );

// TODO series categories? -> race_category prefill with categories defined in series,
//  probably better place to store categories then on the organization level
// race can still have its unique categories

export const rankingModeLabels = messages(
    {
        individuals: "Individual",
        clubs: "Clubs",
    },
    {
        cs: {
            individuals: "jednotlivci",
            clubs: "týmy",
        },
    }
);
export const rankingModeCodec = t.keyof(rankingModeLabels.en);
export type RankingMode = t.TypeOf<typeof rankingModeCodec>;
type ClubRanking = { type: "points"; topResultsCount: number }; // TODO time sum, etc...
