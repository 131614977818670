import * as React from "react";
import ReactCountdown from "react-countdown";
import NoSsr from "@mui/base/NoSsr";
import { messages } from "../../../utils/i18n/i18n";
import { useTranslation } from "../../../utils/i18n/lang";
import { twoDigits } from "../../../domain/timing";
import { Box, Typography } from "@mui/material";

interface Props {
    date: Date;
}

export const Countdown = (p: Props) => {
    const m = useTranslation(msg);

    return (
        <NoSsr>
            <ReactCountdown
                date={p.date}
                renderer={({ days, hours, minutes, seconds, completed }) => {
                    if (completed) {
                        return <Box>{m.started}</Box>;
                    } else {
                        return (
                            <Box>
                                <Typography variant={"body1"} component="span">
                                    {m.startIn}
                                </Typography>{" "}
                                {days > 0 ? (
                                    <Segment label={m.days} count={days} />
                                ) : (
                                    ""
                                )}{" "}
                                {hours > 0 ? (
                                    <Segment
                                        label={m.hours}
                                        count={twoDigits(hours)}
                                    />
                                ) : (
                                    ""
                                )}{" "}
                                <Segment
                                    count={twoDigits(minutes)}
                                    label={m.minutes}
                                />{" "}
                                <Segment
                                    count={twoDigits(seconds)}
                                    label={m.seconds}
                                />
                            </Box>
                        );
                    }
                }}
            />
        </NoSsr>
    );
};

interface SegmentProps {
    count: React.ReactNode;
    label: React.ReactNode;
}
const Segment = (p: SegmentProps) => (
    <>
        <Typography variant={"body1"} component="span" fontWeight={"bold"}>
            {p.count}
        </Typography>{" "}
        <Typography variant={"body2"} component="span">
            {p.label}
        </Typography>
    </>
);

const msg = messages(
    {
        startIn: "start in",
        days: "days",
        hours: "hours",
        minutes: "minutes",
        seconds: "seconds",
        started: "The Race Has Begun!",
    },
    {
        cs: {
            startIn: "start za",
            days: "dní",
            hours: "h",
            minutes: "m",
            seconds: "s",
            started: "Závod byl odstrartován!",
        },
    }
);
